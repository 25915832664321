























































































































































import Vue from 'vue'
import VueSlider from 'vue-slider-component/dist/vue-slider-component.common'
import 'vue-slider-component/theme/default.css'
import Button from '../Button/Button.vue'
import ReplaySettings from '../ReplaySettings/ReplaySettings.vue'

export default Vue.extend({
  components: {
    'ui-replay': ReplaySettings,
    'ui-button': Button,
    'vue-slider': VueSlider
  },
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
    isLive: {
      type: Boolean,
      default: false
    },
    displayControls: {
      type: Boolean,
      default: false
    },
    currentTime: {
      type: Object,
      required: true
    },
    from: {
      type: String
    },
    to: {
      type: String
    },
    frameRate: {
      type: Number
    },
    animationLength: {
      type: Number
    },
    view: String,
    index: Number,
    sliderLength: Number,
    replaySettings: {
      type: Object,
      default: () => ({ })
    },
    displayHeaderAverageTemperature: {
      type: Boolean,
      default: false
    },
    displaySettings: {
      type: Boolean,
      default: true
    },
    hiddenOptions: {
      type: Boolean,
      default: false // Useful to limit component for CustomDashboard
    }
  },
  data: () => ({
    frameRates: [2, 4, 6, 10],
    animationLengths: [{
      display: 'Custom',
      value: 0
    }, {
      display: '15mn',
      value: 15
    }, {
      display: '30mn',
      value: 30
    }, {
      display: '1h',
      value: 60
    }, {
      display: '2h',
      value: 120
    }, {
      display: '3h',
      value: 180
    }, {
      display: '4h',
      value: 240
    }, {
      display: '5h',
      value: 300
    }, {
      display: '6h',
      value: 360
    }],
    displayOptions: false,
    displayReplaySettings: false
  }),
  computed: {
    classPlayPauseButton () {
      return this.isPlaying === true ? 'icon-player-pause' : 'icon-player-play'
    },
    _animationLength: {
      get () {
        return this.animationLength
      },
      set (newValue) {
        this.$emit('change-animation-length', newValue)
      }
    },
    _view: {
      get () {
        return this.view
      },
      set (newValue) {
        this.$emit('update:view', newValue)
      }
    },
    _frameRate: {
      get () {
        return this.frameRate
      },
      set (newValue: number) {
        this.$emit('update:frame-rate', newValue)
      }
    }
  }
})
