/*
  Indice colorTable or Index DBZ range | Val min <= range mm/h < Val max : (Val min - Val max) | Val min <= rangeDB < Val max : (Val min - Val max) | R | G | B
  1 | 0.1 - 0.4 | 8 - 16 | 102 | 0 | 203
  2 | 0.4 - 0.6 | 16 - 20 | 0 | 0 | 255
  3 - 6 | 0.6 - 1.2 | 20 - 24 | 0 | 178 | 255
  7 - 10 | 1.2 - 2.1 | 24 - 28 | 0 | 255 | 255
  11 - 14 | 2.1 - 3.6 | 28 - 32 | 14 | 220 | 210
  15 - 18 | 3.6 - 6.5 | 32 - 36 | 28 | 184 | 165
  19 - 22 | 6.5 - 11.5 | 36 - 40 | 107 | 165 | 48
  23 - 26 | 11.5 - 20.5 | 40 - 44 | 255 | 255 | 0
  27 - 30 | 20.5 - 36.5 | 44 - 48 | 255 | 216 | 0
  31 - 34 | 36.5 - @64.8 | 48 - 52 | 255 | 165 | 0
  35 - 38 | 64.8 - 115.3 | 52 - 56 | 255 | 0 | 0
  39 - 46 | 115.3 - 364.6 | 56 - 64 | 153 | 20 | 7
  47 - 53 | >= 364.6 | 64 - 96 | 255 | 0 | 255
*/
export default [
  ['1', '0.1 - 0.4', '8 - 16', '102', '0', '203', '#6600CB', '8', '0.1'],
  ['2', '0.4 - 0.6', '16 - 20', '0', '0', '255', '#0000FF', '16', '0.4'],
  ['3 - 6', '0.6 - 1.2', '20 - 24', '0', '178', '255', '#00B2FF', '20', '0.6'],
  ['7 - 10', '1.2 - 2.1', '24 - 28', '0', '255', '255', '#00FFFF', '24', '1.2'],
  ['11 - 14', '2.1 - 3.6', '28 - 32', '14', '220', '210', '#0EDCD2', '28', '2.1'],
  ['15 - 18', '3.6 - 6.5', '32 - 36', '28', '184', '165', '#1CB8A5', '32', '3.6'],
  ['19 - 22', '6.5 - 11.5', '36 - 40', '107', '165', '48', '#6BA530', '36', '6.5'],
  ['23 - 26', '11.5 - 20.5', '40 - 44', '255', '255', '0', '#FFFF00', '40', '11.5'],
  ['27 - 30', '20.5 - 36.5', '44 - 48', '255', '216', '0', '#FFD800', '44', '20.5'],
  ['31 - 34', '36.5 - 64.8', '48 - 52', '255', '165', '0', '#FFA500', '48', '36.5'],
  ['35 - 38', '64.8 - 115.3', '52 - 56', '255', '0', '0', '#FF0000', '52', '64.8'],
  ['39 - 46', '115.3 - 364.6', '56 - 64', '153', '20', '7', '#991407', '56', '115.3'],
  ['47 - 53', '>= 364.6', '64 - 96', '255', '0', '255', '#FF00FF', '64', '364.6']
]
