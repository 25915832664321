






































import Dialog from '@/components/ui/Dialog/Dialog.vue'
import palette from '../Legend/palette'

export default {
  components: {
    'ui-dialog': Dialog
  },
  props: {
    radarPeriods: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data () {
    return { palette }
  }
}
