import { render, staticRenderFns } from "./MapLegend.vue?vue&type=template&id=9656a7da&scoped=true&"
import script from "./MapLegend.vue?vue&type=script&lang=ts&"
export * from "./MapLegend.vue?vue&type=script&lang=ts&"
import style0 from "./MapLegend.vue?vue&type=style&index=0&id=9656a7da&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9656a7da",
  null
  
)

export default component.exports